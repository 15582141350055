import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="Footer">
      Built by <a href="https://www.anandjeechoubey.com">Anand</a>. Find the
      source code{" "}
      <a href="https://github.com/Anandjeechoubey/quicksell-assignment">
        here.
      </a>
    </div>
  );
};

export default Footer;
